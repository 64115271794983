import React, { useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/zh";
import SEO from "../components/seo";
import "../style/member-benefit.css";

import $ from "jquery";

const MemberBenefitPage = (props) => {
  useEffect(() => {
    expandData();
  }, []);

  const expandData = () => {
    $(".benefit__mobile label").on("click", function () {
      $(this).toggleClass("benefit__tab--active");
    });
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
          margin: 0px auto
        }
        .benefit__special .table--benefit {
          display: inline-table
        }
      `,
        }}></style>

      <Layout location={props.location}>
        <SEO
          title="More benefits as you grow your membership status "
          keyword="ayana member benefits, benefits for member, ayana rewards"
          decription="Become an AYANA Rewards member and you will receive more benefits as you stay. As you grow your membership level, you will receive more as well. Become a member now and start to earn points!"
        />

        <>
          <div id="contentWrap">
            <div id="index_content" className="admin-setting">
              <div className="benefit__banner">&nbsp;</div>
              <div className="benefit">
                <div className="benefit__banner__mobile mobile--only">
                  <img
                    className="full-width"
                    src="https://resource.ayana.com/live/rewards/page/ARSB.jpg"
                  />
                </div>
                <div className="benefit__content">
                  <div className="container container--600">
                    <h1 className="text--center">会员福利</h1>
                    <p className="text--center">
                      作为AYANA奖励会员，您将在我们的专属目的地享受认可和奢华。我们的三级会员计划加速了您向精英身份的晋升，随着您的消费和访问增长，享受各种福利和优惠。体验与您一样奢华的奖励，无论您是过夜还是仅仅参观。
                    </p>
                  </div>
                  <div className="container container--1000">
                    <table className="table table--benefit table--benefit--main">
                      <thead>
                        <tr>
                          <th valign="middle">计划概述</th>
                          <th style={{ textAlign: "center" }}>
                            <img
                              alt=""
                              className="marr--sm"
                              src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                            />
                            <span style={{ whiteSpace: "nowrap" }}> 银卡 </span>
                          </th>
                          <th style={{ textAlign: "center" }}>
                            <img
                              alt=""
                              className="marr--sm"
                              src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                            />
                            <span style={{ whiteSpace: "nowrap" }}> 金卡 </span>
                          </th>
                          <th style={{ textAlign: "center" }}>
                            <img
                              alt=""
                              className="marr--sm"
                              src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                            />
                            <span style={{ whiteSpace: "nowrap" }}>
                              {" "}
                              白金卡{" "}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>升级会员等级所需的最低积分/夜晚 </td>
                          <td>-</td>
                          <td>20晚或8,000积分</td>
                          <td>50晚或20,000积分</td>
                        </tr>
                        <tr>
                          <td>保持会员等级所需的最低积分/夜晚 </td>
                          <td>-</td>
                          <td>20晚或8,000积分</td>
                          <td>50晚或20,000积分</td>
                        </tr>
                        <tr>
                          <td>积分失效期限 </td>
                          <td colSpan="3">自最后一次合格活动起2年 </td>
                        </tr>
                        <tr>
                          <td>等级有效期或保留期 </td>
                          <td colSpan="3">
                            有效期至最后升级/降级/保留后的下一日历年的12月31日{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>活跃状态 </td>
                          <td colSpan="3">
                            {" "}
                            会员需要在最后记录的活动日期起24个月内参与网站登录活动、合格住宿、合格消费或兑换AYANA奖励积分。{" "}
                          </td>
                        </tr>
                        <tr className="table--benefit--subheader">
                          <td colspan="4">赚取积分</td>
                        </tr>
                        <tr>
                          <td>每消费10,000印尼盾所赚取的积分</td>
                          <td>1分</td>
                          <td>1分</td>
                          <td>1分</td>
                        </tr>
                        <tr>
                          <td>
                            每消费15,000印尼盾所赚取的积分（加拉璜凤凰木酒店）
                          </td>
                          <td>1分</td>
                          <td>1分</td>
                          <td>1分</td>
                        </tr>
                        <tr>
                          <td>有效住宿所赚取的积分</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>餐饮消费所赚取的积分</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>水疗护理消费所赚取的积分</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr className="table--benefit--subheader">
                          <td colspan="4">会员福利</td>
                        </tr>
                        <tr>
                          <td>会员专属房间折扣** </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>同一预订房间类别的免费1级房间升级* </td>
                          <td>&nbsp;</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>合资格消费的奖励积分 </td>
                          <td>&nbsp;</td>
                          <td>25%</td>
                          <td>50%</td>
                        </tr>
                        <tr>
                          <td>提前入住* </td>
                          <td>&nbsp;</td>
                          <td>11:00</td>
                          <td>11:00</td>
                        </tr>
                        <tr>
                          <td>延迟退房* </td>
                          <td>&nbsp;</td>
                          <td>16:00</td>
                          <td>18:00</td>
                        </tr>
                        <tr>
                          <td>免费一次早餐 </td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>2人</td>
                        </tr>
                        <tr>
                          <td>欢迎礼包** </td>
                          <td>&nbsp;</td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>生日期间的免费设施** </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>健康计划福利** </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                          <td>
                            <img
                              alt="yes"
                              src="https://resource.ayana.com/live/rewards/icon/check.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">参与品牌的会员专属福利*** </td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            Kawano集团餐厅的会员专属折扣（全国）{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>10%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            在Liu Li Palace海鲜餐厅（雅加达）的会员专属折扣{" "}
                          </td>
                          <td>15%</td>
                          <td>15%</td>
                          <td>15%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            AYANA巴厘岛餐厅的会员专属折扣{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            在Delonix Hotel Karawang的迈阿密餐厅的会员专属折扣{" "}
                          </td>
                          <td>10%</td>
                          <td>15%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            在Riverside高尔夫俱乐部的会员专属果岭费折扣{" "}
                          </td>
                          <td>15%</td>
                          <td>25%</td>
                          <td>40%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            在AYANA Midplaza Jakarta的AYANA Spa的会员专属折扣{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            在AYANA科莫多Waecicu海滩的AYANA Spa的会员专属折扣{" "}
                          </td>
                          <td>10%</td>
                          <td>10%</td>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <td style={{ paddingLeft: "25px" }}>
                            在AYANA Spa - AYANA巴厘的会员专属福利{" "}
                          </td>
                          <td>额外15分钟按摩</td>
                          <td>额外15分钟按摩</td>
                          <td>额外30分钟按摩</td>
                        </tr>
                      </tbody>
                    </table>
                    <small className="mart--lg" style={{ display: "block" }}>
                      * 依据会员到达时的空房情况
                      <br />
                      ** 根据物业而有所不同
                      <br />
                      ***
                      <Link className="link" to="/zh/terms-and-conditions-8">
                        <span> 适用条款和条件 </span>
                      </Link>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </>
  );
};

export default MemberBenefitPage;
